<template>
  <v-row class="bg-page-secondary text-contrast h-100 m-auto">
    <v-col
      cols="12"
      xl="3"
      lg="3"
      md="4"
      sm="6"
      xs="12"
      class="d-flex flex-column justify-space-evenly mx-auto"
    >
      <div class="m-3">
        <router-link to="/login">
          <v-img
            style="height: 8vh"
            :src="require('../../../assets/images/legenda-logo.svg')"
          ></v-img>
        </router-link>
      </div>

      <div class="email-form-style">
        <div class="h5 mb-4">{{ $t("legenda_132") }}</div>
        <alert
          :variant="alertVariant"
          :messageList="alertMessages"
          v-if="showAlert"
        ></alert>

        <v-form @submit.prevent="submit">
          <v-text-field
            @keydown.space.prevent
            class="my-4"
            color="input-primary"
            type="email"
            required
            :rules="[rules.required, rules.emailValidation]"
            :hint="$t('legenda_139')"
            v-model="user.username"
            :label="$t('legenda_140')"
            :placeholder="$t('legenda_141')"
            prepend-inner-icon="mdi-email"
            variant="outlined"
          ></v-text-field>

          <div style="text-align: center">
            <v-btn
              class="reset-link-btn"
              type="submit"
              color="button-primary"
              variant="elevated"
              :disabled="!validateEmail(user.username)"
              >{{ $t("legenda_135") }}</v-btn
            >
          </div>
        </v-form>
      </div>
      <div class="mt-3">
        <metis_footer></metis_footer>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import AlertComponent from "../../../components/AlertComponent.vue";
import FooterComponent from "../../../components/FooterComponent.vue";
import { mapActions } from "vuex";

export default {
  name: "ForgotPasswordView",

  components: {
    alert: AlertComponent,
    metis_footer: FooterComponent,
  },

  data() {
    return {
      user: {
        username: "",
        password: "",
      },

      responseStatusCode: 0,

      alertMessages: [],
      showAlert: false,
      rules: {
        required: (value) => !!value || this.$t("legenda_104"),
        emailValidation: (value) =>
          this.validateEmail(value) || this.$t("legenda_060"),
      },
    };
  },

  computed: {
    alertVariant() {
      return this.responseStatusCode == 200 ? "success" : "warning";
    },
  },

  methods: {
    ...mapActions(["preUpdatePassword"]),

    async submit() {
      try {
        const response = await this.preUpdatePassword(this.user);

        this.link = null;
        this.showAlert = true;
        this.responseStatusCode = response.status;
        this.alertMessages = [{ text: response.data.message }];
      } catch (error) {
        this.showAlert = true;
        this.responseStatusCode == error.response
          ? error.response.status
          : error.code;

        const alertMsg =
          this.responseStatusCode === 403
            ? "legenda_253"
            : {
                text: "legenda_086",
                links: [
                  {
                    href: "mailto:info@metisai.it",
                    placeholder: this.$t("legenda_087"),
                    customClass: "text-typo-blue",
                    openInTab: false,
                  },
                ],
              };

        this.alertMessages = [alertMsg];
      }
    },

    validateEmail(value) {
      return String(value)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
  },
};
</script>

<style>
#app
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(1)
  > div
  > form
  > div:nth-child(3)
  > div
  > div.v-input__control
  > div
  > div.v-field__append-inner {
  color: green !important;
}
.email-form-style {
  margin: 0 auto;
  padding: 1vh;
  width: 100%;
}
.reset-link-btn {
  display: flex;
  width: 70%;
  margin: 0 auto;
}
</style>
